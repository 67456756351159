export const DETAILS = {
    name: " فروشگاه سلنا",
    shortName: "سلنا",
    footerName: "فروشگاه سلنا",
    description: "",
    shortAbout: "فروشگاه سلنا بزرگترین و معتبرترین فروشگاه لباس زیر در شهر کرج می باشد که هم به صورت حضوری و هم فروش آنلاین فعالیت گسترده ای دارد. در فروشگاه سلنا، بهترین برندهای لباس زیر موجود در بازار در دسترس مشتریان قرار دارند. کیفیت، زیبایی و راحتی لباس زیرهای سلنا آن چیزیست که رضایت مشتریان ما را همواره جلب کرده است.",
    veryShortAbout: "فروشگاه سلنا بزرگترین و معتبرترین فروشگاه لباس زیر در شهر کرج می باشد که هم به صورت حضوری و هم فروش آنلاین فعالیت گسترده ای دارد. در فروشگاه سلنا، بهترین برندهای لباس زیر موجود در بازار در دسترس مشتریان قرار دارند. کیفیت، زیبایی و راحتی لباس زیرهای سلنا آن چیزیست که رضایت مشتریان ما را همواره جلب کرده است.",
    link: "https://selenacenter.com",
    mobile: ["09380637888"],
    phone: [],
    email: [],
    postalCode: [],
    address: "کرج، شاهین ویلا، خیابان قلم، بین خیابان ۱۶و ۱۷ غربی",
    supportDescription: "",
    workingHours: ["شنبه تا پنجشنبه 10 تا 13 و 16:30 تا 21", "جمعه 17 تا 21"],
    mapIframeSrc: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d202.09165059846546!2d50.95647521682943!3d35.862085419120525!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f8db9260e72e0c1%3A0xe879936cfc61ee1f!2z2LPZhNmG2Kcg2YTYqNin2LM!5e0!3m2!1sen!2s!4v1706425018736!5m2!1sen!2s",
    socialMedia: [
        {
            title: 'واتس اپ',
            value: '09380637888',
            name: 'whatsapp',
            link: 'https://api.whatsapp.com/send/?phone=09380637888&text&app_absent=0',
            icon: <i className="lab la-whatsapp"/>,
            isPrimary: true
        },
        /*{
            title: 'تلگرام',
            value: '09357195494',
            name: 'telegram',
            link: 'https://t.me/09357195494',
            icon: <i className="lab la-telegram-plane"/>,
            isPrimary: true
        },*/
        {
            title: 'اینستاگرام',
            value: 'selenacollectionkaraj',
            name: 'instagram',
            link: "https://instagram.com/selenacollectionkaraj",
            icon: <i className="lab la-instagram"/>,
            isPrimary: true
        },
        /*{
                    title: 'ایتا',
                    value: '09357195494',
                    name: 'eitaa',
                    link: 'https://eitaa.com/09357195494',
                    icon: <img src={eitaaIcon} alt='ایتا'/>,
                    isPrimary: false
                }
        {
            title: 'ایمیل',
            value: 'Volvotak@gmail.com',
            name: 'email',
            link: 'mailto:Volvotak@gmail.com',
            icon: <i className="las la-envelope"></i>,
            isPrimary: true
        },
        {
            title: 'تس',
            value: '09133377432',
            name: 'phone',
            link: 'tel:989133377432',
            icon: <PhoneCall/>,
            isPrimary: true
        },
                /*{
                    title: 'آپارات',
                    value: 'Alo_aghsat',
                    name: 'aparat',
                    link: 'https://www.aparat.com/Alo_aghsat',
                    icon: <SiAparat size={25}/>,
                    isPrimary: true
                },
                {
                    title: 'تماس',
                    value: '02636634893',
                    name: 'phone',
                    link: 'tel:+982636634893',
                    icon: <PhoneCall/>,
                    isPrimary: false
                },
                {
                    title: 'سروش پلاس',
                    value: '09331074475',
                    name: 'soroushPlus',
                    link: '',
                    icon: <img src={soroushIcon} alt='سروش'/>,
                    isPrimary: false
                },
                {
                    title: 'سروش پلاس',
                    value: '09332094475',
                    name: 'soroushPlus',
                    link: '',
                    icon: <img src={soroushIcon} alt='سروش'/>,
                    isPrimary: false
                },
                {
                    title: 'سروش پلاس',
                    value: '09330324475',
                    name: 'soroushPlus',
                    link: '',
                    icon: <img src={soroushIcon} alt='سروش'/>,
                    isPrimary: false
                },
                {
                    title: 'ایتا',
                    value: '09331074475',
                    name: 'eitaa',
                    link: '',
                    icon: <img src={eitaaIcon} alt='ایتا'/>,
                    isPrimary: false
                },*/
    ],
    services: [],
    brands: [
        /*{
            name: 'Apple',
            image: <img src={appleImage} alt='apple'/>,
        },*/
    ],
    "enamadLink": "",
    "samandehiLink": "",
    "ZarinpalLink": "https://www.zarinpal.com/webservice/TrustCode"
}
